import React from "react"
import {Link} from "gatsby"
import PropTypes from "prop-types"

import "./footer.scss"
import TermsOfSales from "../termsOfSales/termsOfSales";

const Footer = ({button, link, buttonAction, isButton, children}) => (
    <footer className={"sr-footer"}>
        <div>
            {isButton ?
                <button className={"btn btn-info btn-round"} onClick={buttonAction}>{button}</button>
                :
                <Link to={link} className={"btn btn-info btn-round"}>{button}</Link>
            }
        </div>
        {children}    
    
    </footer>
)

Footer.propTypes = {
    button:         PropTypes.string,
    link:           PropTypes.string,
    children:       PropTypes.node,
    buttonAction:   PropTypes.func,
    isButton:       PropTypes.bool,
}

Footer.defaultProps = {
    button: "Déjà inscrit ? Connectez-vous",
    link: "login",
    children: <TermsOfSales subtitle={"Condition générales"}/>,
    isButton: false,
}

export default Footer
