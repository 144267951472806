import React from 'react'

import SRModal from "../modal/modal"

const TermsOfSales = ({text}) => (
    <SRModal 
    title={"General Terms and Conditions of Sale"}
    text={text}
    className={"addMember"}>
        <p>
    <li>Cancellation requests received in writing before 21 September will receive a full refund, minus a 25 euro for handling fee.</li>
<li>There will be no refund on cancellations after 21 September, even if due to illness, visa rejection, or any other reason.</li>
<li>All refunds will be paid after the conference.</li>
<li>Residents from low- and middle- income countries may transfer their registration for a 50 euro fee. High income country delegate may transfer their registration for a 80 euro fee. Name changes can be possible until 16 October.</li>
<li>Name changes onsite will be considered as a new registration</li>
<li>Badge reprinting onsite upon official ID presentation will be 50 euro</li>
    </p>
    </SRModal>
)

export default TermsOfSales
