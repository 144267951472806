import React from "react"
import PropTypes from 'prop-types'

import "./header.scss"

const Header = ({title, subtitle, image}) => (
    <header className={"sr-header"}>
    </header>
)

Header.propTypes = {
    title:      PropTypes.string,
    subtitle:   PropTypes.string,
    image:      PropTypes.node,
}

Header.defaultProps = {
    title: "Advancing prevention",
    subtitle: "21-24 october 2020 | Seville, Spain"
}

export default Header
