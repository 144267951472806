import React from "react"

import "./title.scss"

const Title = () => (
    <div className={"sr-main-title"}>
        <p className={"title"}>
            Inscription pour le<br/>
            <strong>75e CONGRES de l’Ordre des experts-comptables</strong><br/>
        </p>

        <p className={"select-category"}>
            Sélectionnez votre catégorie
        </p>
    </div>
)

export default Title
